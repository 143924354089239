<template>
  <div class="service-table">
    <div class="service-table__head">
      <div class="service-table__prop">{{ categoryName }}</div>
      <div class="service-table__value">{{ serviceName }}</div>
    </div>
    <div class="service-table__body">
      <div class="service-table__row">
        <div class="service-table__prop">Дата</div>
        <div class="service-table__value">{{ data.date | formatDate('DD.MM.YYYY', 'DD MMMM YYYY') }}</div>
      </div>
      <div class="service-table__row">
        <div class="service-table__prop">Количество часов в день</div>
        <div class="service-table__value">{{ data.hours_per_day }}</div>
      </div>
      <div class="service-table__row">
        <div class="service-table__prop">В какое время</div>
        <div class="service-table__value">{{ data.time | formatTime }}</div>
      </div>
      <div class="service-table__row">
        <div class="service-table__prop">Стоимость</div>
        <div class="service-table__value">{{ data.price }} руб.</div>
      </div>

    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>

import {mapState, mapGetters} from "vuex";

export default {
  name: "ServiceTable",
  props: {
    data: Object
  },
  computed: {
    ...mapState({
      categories: state => state.enums.categories
    }),
    ...mapGetters({
      services: 'enums/services'
    }),
    categoryName() {
      return this.data?.category?.name;
    },
    serviceName() {
      return this.data?.responsibility?.name;
    },
  }
};

</script>

<style scoped lang="scss">

// service-table
.service-table {
  margin-top: 16px;
  padding: 0 15px;
  border-radius: 10px;
  border: 1px solid rgba(118, 118, 128, 0.12);
  .service-table__head {
    padding: 15px 0;
    border-bottom: 1px solid rgba(163, 163, 163, 0.3);
    .service-table__prop {
      margin-bottom: 5px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 15px;
    }
    .service-table__value {
      color: #000000;
      font-size: 19px;
    }
  }
  .service-table__body {
    padding: 16px 0 4px;
    .service-table__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 11px;
    }
    .service-table__prop {
      color: #000000;
      font-size: 15px;
    }
    .service-table__value {
      color: #0C76DA;
      font-size: 15px;
    }
    .service-table__info {
      color: #000000;
      font-size: 15px;
    }
  }
}

</style>

<template>
  <div class="modal-evaluate">

    <div class="modal-evaluate__head">
      <div class="modal-evaluate__title">Оценить работу</div>
      <div class="modal-evaluate__text">Оцените проделанную работу исполнителем. Данный рейтинг не будет отображаться напрямую исполнителю.</div>
    </div>

    <div class="modal-evaluate__rate">

      <v-rating
        hover
        length="5"
        size="40"
        v-model="rate"
      ></v-rating>

      <button
        type="button"
        class="btn btn--prime"
        :disabled="!rate"
        @click="evaluate"
      >Оценить</button>

    </div>
  </div>
</template>

<script>

export default {
  props: {
    resolve: {
      type: Function,
      default: () => true
    }
  },
  data() {
    return {
      rate: null,
    };
  },
  methods: {
    evaluate() {
      this.resolve(this.rate);
    }
  }
};
</script>

<style lang="scss" scoped>

// modal-evaluate
.modal-evaluate {
  width: 100%;
  max-width: 400px;
  @include respond-to('xs') {
    max-width: 375px;
  }
  .modal-evaluate__head {
    padding: 20px 28px 8px;
    text-align: center;
    border-bottom: 1px solid rgba(#000000, 0.2);
  }
  .modal-evaluate__title {
    margin-bottom: 10px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
  }
  .modal-evaluate__text {
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
  }
  .modal-evaluate__rate {
    padding: 30px 28px 15px;
  }
  ::v-deep .v-rating {
    margin-bottom: 30px;
    text-align: center;
    .v-icon {
      margin-right: 16px;
      padding: 0;
      color: #FFA618;
    }
  }
  .btn {
    width: 100%;
  }
}

</style>

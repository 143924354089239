<template>
  <div class="page-content">
    <div class="container">
      <NavBtn :list="navList" />
      <div class="panel-request">
        <div class="panel-request__num">Номер заявки <span>№1256</span></div>
        <div class="request-status request-status--completed">
          <span>Выполненная</span>
        </div>
        <div class="panel-request__block">
          <h3 class="panel-request__title">Исполнитель</h3>
          <BlockPerson
            :person="{
              name: 'Василий Барка',
              image: require('@img/temp/avatar-2.jpg'),
              rate: 4,
            }"
          />
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text">1,3 км от вас</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.999 1.833c-3.667 0-6.667 3-6.667 6.667s3 6.667 6.667 6.667c3.666 0 6.666-3 6.666-6.667s-3-6.667-6.666-6.667zm0 12c-2.934 0-5.334-2.4-5.334-5.333 0-2.933 2.4-5.333 5.334-5.333 2.933 0 5.333 2.4 5.333 5.333 0 2.933-2.4 5.333-5.333 5.333z" fill="#0C72D6"/><path d="M8.332 5.167h-1v4l3.467 2.066.533-.8-3-1.8V5.167z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text">Начало</div>
            </div>
            <div class="panel-request__value">16 апр. 2020 г. 16:00</div>
          </div>
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text">Стоимость: 700 руб./день</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Дата рождения</div>
              <div class="panel-request__field-value">12/5/1978</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__about">
              <div class="panel-request__label">О себе</div>
              <div class="panel-request__text">Краткое описание о себе, которое заполнил пользователь.</div>
            </div>
          </div>
        </div>
        <div class="panel-request__block">
          <h3 class="panel-request__title">Создал заявку</h3>
          <div class="panel-request__item">
            <BlockPerson
              class="block-person--lg"
              :person="{
                name: 'Остап Паляниця',
                image: require('@img/temp/avatar-1.jpg'),
                date: '24 декабря 2020, 19:53',
              }"
            />
          </div>
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text">Медицинская помощь</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text">Стоимость: 500 руб./день</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text">Lorem ipsum dolor sit amet</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__descr">
              <div class="panel-request__text">Краткое описание заявки, которое заполнил пользователь, оставивший заявку.</div>
            </div>
          </div>
        </div>
        <div class="panel-request__block">
          <h3 class="panel-request__title">Нуждается в помощи</h3>
          <div class="panel-request__item">
            <BlockPerson
              class="block-person--lg"
              :person="{
                name: 'Томас Квитка',
                image: require('@img/temp/avatar-1.jpg'),
                gender: 'Мужской',
              }"
            />
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Дата рождения</div>
              <div class="panel-request__field-value">12/5/1978</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Вес</div>
              <div class="panel-request__field-value">90 кг</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Диагноз</div>
              <div class="panel-request__field-value">Маниакально-депрессивный психоз</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Где выполнять услуги</div>
              <div class="panel-request__field-value">На дому</div>
            </div>
          </div>
          <ServiceTable />
        </div>
      </div>
      <div class="page-content__btn-gp">
        <v-dialog v-model="dialogEvaluate">
          <template v-slot:activator="{ on, attrs }">
            <button type="button" class="btn btn--border" v-on="on" v-bind="attrs">Оценить работу</button>
          </template>
          <v-card>
            <div class="btn-close" @click="dialogEvaluate = false">
              <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
            </div>
            <ModalEvaluate />
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="page-content__footer">
      <v-dialog v-model="dialogSupport">
        <template v-slot:activator="{ on, attrs }">
          <div class="page-content__link" v-on="on" v-bind="attrs">Техническая поддержка</div>
        </template>
        <v-card>
          <div class="btn-close" @click="dialogSupport = false">
            <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
          </div>
          <ModalSupport />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import NavBtn from '@component/Module/NavBtn';
import BlockPerson from '@component/Module/BlockPerson';
import ServiceTable from '@component/Module/ServiceTable';
import ModalEvaluate from '@component/Modal/ModalEvaluate';
import ModalSupport from '@component/Modal/ModalSupport';

export default {
  name: 'CompletedApplication',
  components: {
    NavBtn,
    BlockPerson,
    ServiceTable,
    ModalEvaluate,
    ModalSupport,
  },
  data() {
    return {
      navList: [
        {
          label: 'Заявки в работе',
          name: 'ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS',
          icon: require('@img/icons/app-new-provide.svg'),
        },
        {
          label: 'Отклики',
          name: 'ASSISTANCE_PROVIDE_COMPANY_RESPONSES',
          icon: require('@img/icons/app-responses-provide.svg'),
        },
        {
          label: 'Выполненные',
          name: 'ASSISTANCE_PROVIDE_COMPANY_COMPLETED_APPLICATIONS',
          icon: require('@img/icons/app-completed-provide.svg'),
        },
      ],
      rateVue: 4,
      dialogSupport: false,
      dialogEvaluate: false,
    };
  },
};
</script>

<style lang="scss" scoped>

// panel-request
.panel-request {
  position: relative;
  margin-bottom: 40px;
  padding: 12px 27px 21px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid rgba(118, 118, 128, 0.12);
  .request-status {
    position: absolute;
    top: 12px;
    right: 30px;
  }
  .block-person {
    margin-top: 10px;
    margin-bottom: 18px;
  }
  .panel-request__num {
    margin-bottom: 9px;
    color: rgba(2, 2, 2, 0.3);
    font-size: 15px;
    span {
      color: #020202;
    }
  }
  .panel-request__title {
    margin-bottom: 19px;
    color: #020202;
    font-size: 17px;
    font-weight: 600;
  }
  .panel-request__value {
    color: #0C76DA;
    font-size: 15px;
  }
  .panel-request__label {
    margin-bottom: 15px;
    color: #000000;
    font-size: 17px;
  }
  .panel-request__text {
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
  }
  .panel-request__block {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .panel-request__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(163, 163, 163, 0.3);
    }
  }
  .panel-request__about {
    padding: 20px 0 10px;
  }
  .panel-request__descr {
    padding: 16px 0 10px;
  }
  .ic-ell {
    padding: 16px 0;
  }
  .panel-request__field {
    padding: 11px 0;
    .panel-request__field-prop {
      margin-bottom: 6px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 15px;
    }
    .panel-request__field-value {
      color: #000000;
      font-size: 17px;
    }
  }
}

</style>

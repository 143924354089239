import { render, staticRenderFns } from "./CompletedApplication.vue?vue&type=template&id=dccfb9d8&scoped=true&"
import script from "./CompletedApplication.vue?vue&type=script&lang=js&"
export * from "./CompletedApplication.vue?vue&type=script&lang=js&"
import style0 from "./CompletedApplication.vue?vue&type=style&index=0&id=dccfb9d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dccfb9d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VDialog})
